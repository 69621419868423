<template>
  <v-card>
    <v-row dense>
      <v-col cols="12">
        <v-data-table
          dense
          disable-sort
          item-key="id"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalRecords"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right'
          }"
        >
          <template v-slot:[`item.movementValue`]="{ item }">
            {{ formatMoney(item.movementValue) }}
          </template>

          <template v-slot:[`item.effectiveDate`]="{ item }">
            {{ formatDate(item.effectiveDate) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-divider class="mb-3" />

    <v-card-actions class="b_tabs__items__card--action">
      <v-spacer />
      <BaseButton
        id="btn-execute"
        name="btn-execute"
        color="primary"
        title="Exportar"
        @click="execute"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import { formatDate, formatMoney } from '@/helpers/formatting';
import { mapGetters } from 'vuex';
import { downloadFile } from '@/helpers/download';
import { InvoiceService } from '@/services/api/invoice';

export default {
  data: () => ({
    items: [],
    totalRecords: 0,
    options: {},
    headers: [
      { text: 'Tipo', value: 'movementType' },
      { text: 'Beneficiário', value: 'beneficiaryName' },
      { text: 'Vigência', value: 'effectiveDate' },
      { text: 'Cobrança', value: 'charge' },
      { text: 'Valor', value: 'movementValue' }
    ]
  }),

  computed: {
    ...mapGetters({
      invoice: 'invoice/getInvoice'
    })
  },

  methods: {
    formatDate,
    formatMoney,

    async search() {
      try {
        const { page, itemsPerPage } = this.options;

        const invoiceService = new InvoiceService();
        const { status, data } = await invoiceService.getMovements({
          invoiceId: this.invoice.id,
          page,
          take: itemsPerPage
        });

        if (status === 200) {
          this.items = data.results;
          this.totalRecords = data.totalRecords;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async execute() {
      try {
        const invoiceService = new InvoiceService();
        const { status, data } = await invoiceService.downloadInvoiceMovements({
          invoiceId: this.invoice.id
        });

        if (status === 200) {
          const { fileContent, fileName } = data;
          downloadFile(fileContent, fileName);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  }
};
</script>
