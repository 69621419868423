var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"dense":"","disable-sort":"","item-key":"id","headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalRecords,"items-per-page":10,"footer-props":{
          itemsPerPageOptions: [10, 20, 50, 100],
          showFirstLastPage: true,
          firstIcon: 'first_page',
          lastIcon: 'last_page',
          prevIcon: 'chevron_left',
          nextIcon: 'chevron_right'
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.movementValue`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatMoney(item.movementValue))+" ")]}},{key:`item.effectiveDate`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.effectiveDate))+" ")]}}],null,true)})],1)],1),_c('v-divider',{staticClass:"mb-3"}),_c('v-card-actions',{staticClass:"b_tabs__items__card--action"},[_c('v-spacer'),_c('BaseButton',{attrs:{"id":"btn-execute","name":"btn-execute","color":"primary","title":"Exportar"},on:{"click":_vm.execute}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }